import React from 'react'
import './about.css'
//import ME from '../../assets/me-about.jpg'
import ME from '../../assets/me-about.png'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About me" />
          </div>
        </div>
        
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>1+ Years experience</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article>
          </div>

          <p>
          I'm an energetic, self-studied, hard working and ambitious young man who is motivated to learn new things . 
          I'm a still studying junior web developer who developed and maintained a learning management system which were used by over 200+ people within a different roles.
          I have the patience to fix a bug and designing a database structure is my enjoyment. 
          I like working in a team because you will learn faster and much more.
          </p>
          <p>
          I am excited to continue developing my skills and gaining valueable experiences as an intern. For a closer look at the professional work I have done check out my <a href="#portfolio">portfolio</a>.</p>
          <p>
            Thank you for taking the time to visit my website. Have a great day. 
          </p>

          <a href="#contact" className='btn btn-primary'> Email Me</a>
        </div>
      </div>
    </section>
  )
}

export default About