import React from 'react'
import './footer.css'
import {FiGithub} from 'react-icons/fi'
import {FaLinkedin} from 'react-icons/fa'
import { useState } from 'react'


const Footer = () => {
  const [activeN, setActiveN] = useState('#')

  return (
    <footer>
      <a href="#" className='footer__logo'>Abdullahi Mahamud</a>
      
      <ul className='permalinks'>
        <li><a href="#about" onClick={() => setActiveN('#about')} className={activeN === '#about' ? 'active' : ''}>About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://github.com/abdu114" target="_blank"><FiGithub/></a>
        <a href="https://www.linkedin.com/in/abdullahi-mahamud-b65311235" target="_blank"><FaLinkedin/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Abdullahi Mahamud </small>
      </div>
    </footer>
  )
}

export default Footer