import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/frontpage.png'
import IMG2 from '../../assets/admin-statics.png'
import IMG3 from '../../assets/parent-statics.png'
import IMG4 from '../../assets/gridbox.png'
import IMG5 from '../../assets/reading.png'
import IMG6 from '../../assets/new.png'
import IMG7 from '../../assets/edit.png'
import IMG8 from '../../assets/img8.png'
import IMG9 from '../../assets/img9.png'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG8,
    title: '1. This portfolio -- Using React JS and Email JS',
    //demo: 'https://me.lasoco.fi'
  },
  {
    id: 2,
    image: IMG1,
    title: '2. Lasoco.fi || Login page -- Using Materialize CSS & PHP MySql',
    demo: 'https://lasoco.fi'
  },
  {
    id: 3,
    image: IMG2,
    title: '3. Admin Dashboard -- Using PHP MySql & Morris.js ',
    demo: 'https://lasoco.fi/port/statics.html#'
  },
  {
    id: 4,
    image: IMG3,
    title: '4. Dashboard for the parents -- Using PHP MySql and SVG animations',
    demo: 'https://lasoco.fi/port/parent-statics.html'
  },
  {
    id: 5,
    image: IMG4,
    title: '5. Responsive GridBox for articles -- Using Materialize CSS & PHP MySql',
    demo: 'https://lasoco.fi/port/gridbox.html'
  },
  {
    id: 6,
    image: IMG5,
    title: '6. Dynamic page to read articles -- Using PHP MySql and Materialize CSS',
    demo: 'https://lasoco.fi/port/reading.html'
  },
  {
    id: 7,
    image: IMG6,
    title: '7. Creating a new article page -- Using CKEditor 4, Materialize CSS & PHP MySql',
    demo: 'https://lasoco.fi/port/new.html'
  },
  {
    id: 8,
    image: IMG7,
    title: '8. Editing an article page -- Using CKEditor 4, Materialize CSS & PHP MySql',
    demo: 'https://lasoco.fi/port/edit.html'
  },
  {
    id: 9,
    image: IMG9,
    title: '9. City Bike web app: Web application that visualizes data from DB -- Using PHP MySql, AJAX and Google Maps API',
    demo: 'https://lasoco.fi/port/city-bike/index.php'
  }
  
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={demo} className='btn btn-primary' id="btn-demoo" target='_blank'>Live Demo</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio